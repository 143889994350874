import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FlapUtil from 'Utils/content/flap-util';
import Config from 'Webapp/shared/config';

import {
  getSection,
  setSectionAccessed,
  refreshForYou,
} from 'Webapp/shared/app/redux/actions/section-actions';
import {
  usageTrackSectionEnter,
  usageTrackSectionExit,
} from 'Webapp/shared/app/redux/actions/usage-actions';
import { fetchFollowing } from 'Webapp/shared/app/redux/actions/profile-actions';
import ForYouFeed from '../components/for-you-feed';

const mapStateToProps = ({
  sections,
  profile: {
    userInfo,
    following,
    followingIds,
    followingStale,
    followingLoading,
  },
  app,
  auth,
}) => {
  const currentSection = FlapUtil.sectionOrDefaults(
    FlapUtil.getSectionByRemoteId(
      Config.FOR_YOU_FEED_REMOTE_ID,
      sections.entries,
    ),
  );
  const shouldLoadFollowedTopics =
    following.length === 0 && followingIds.length > 0;

  const followedTopics = shouldLoadFollowedTopics ? null : following;

  return {
    followedTopics,
    followingIds,
    followingLoading,
    followingStale,
    getSection: sections.getSection,
    isLoading: currentSection.placeholder || false,
    items: currentSection.items,
    neverLoadMore: currentSection.neverLoadMore,
    nextPageKey: currentSection.nextPageKey,
    nglFeedConfigs: app.nglFeedConfigs,
    previousRawItems: currentSection.rawItems,
    section: currentSection,
    topicDescriptions: sections.topicDescriptions,
    userInfo,
    userid: auth.uid,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchFollowing,
      getSection,
      refreshForYou,
      setSectionAccessed,
      usageTrackSectionEnter,
      usageTrackSectionExit,
    },
    dispatch,
  );

const ForYouFeedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForYouFeed);

export default ForYouFeedContainer;
