import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Config from '../../config';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { BREAKPOINTS } from 'Style/breakpoints';
import { COLORS, TEXT_COLORS } from 'Style/colors';
import { PAGE_STYLES } from 'Style/layout';

import {
  UI_TEXT_TYPES,
  FONT_WEIGHTS,
  FONTS,
  UI_SUBHEADING_TYPES,
} from 'Style/typography';

// Utils
import { USAGE_DISPLAY_STYLES } from 'Utils/analytics/usage';

// Icons
import WebFlipboardLandscapeLogo from './logos/web-flipboard-landscape';

// Components
import Button, {
  StyleVariations,
  StyleModifiers,
  BUTTON_STYLES,
} from 'Webapp/shared/app/components/button';
import SignupButton from 'Webapp/shared/app/containers/signup-button';
import FrictionlessForm, {
  MainForm,
  FrictionlessSubmit,
} from 'Webapp/shared/app/components/frictionless-form';
import SignupDisclaimer from 'Webapp/shared/app/components/signup-disclaimer';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectAppUrl from 'Webapp/shared/app/connectors/connectAppUrl';
import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';

import withT from 'ComponentLibrary/hocs/withT';
import withNavFrom from 'Webapp/shared/app/hocs/withNavFrom';
import withAdjustUrl from 'Webapp/shared/app/hocs/withAdjustUrl';
import { FrictionlessFlowType } from 'Webapp/enums';

const Header = styled.header(
  {
    textAlign: 'center',
    padding: '0',
  },
  BREAKPOINTS.tabletPortraitUp({ padding: '100px 0' }),
);

const Logo = styled(WebFlipboardLandscapeLogo)({
  display: 'block',
  margin: `0 auto ${SPACING.BASE6X}`,
});

const Heading = styled.h1(
  UI_TEXT_TYPES.TITLE_LARGE,
  {
    display: 'inline-block',
    marginBottom: SPACING.LARGE,

    'div:last-child': {
      borderBottom: `${SPACING.BASE} solid ${COLORS.red}`,
      display: 'inline-block',
      padding: `0 0 ${SPACING.BASE}`,
    },
  },
  BREAKPOINTS.tabletPortraitUp({
    fontSize: '60px',
    lineHeight: '43px',
    'div:last-child': {
      borderBottom: `11px solid ${COLORS.red}`,
      display: 'inline-block',
      padding: '12px 0 11px',
    },
  }),
);

const SubHeader = styled.h2(
  {
    fontFamily: FONTS.FONT_FAKT_FLIPBOARD,
    fontWeight: FONT_WEIGHTS.MEDIUM,
    fontSize: '20px',
    lineHeight: '1.4',
    color: TEXT_COLORS.primary,
    marginBottom: SPACING.BASE4X,
  },
  BREAKPOINTS.tabletPortraitUp({ fontSize: '24px' }),
);
const SubHeaderActionWrapper = styled.div(
  {
    width: '280px',
    margin: '0 auto',
    display: 'block',
    '*': {
      display: 'block',
    },
  },
  BREAKPOINTS.tabletPortraitUp({
    width: 'auto',
    '*': { display: 'inline-block' },
  }),
);
const StyledFrictionlesForm = styled(FrictionlessForm)(
  PAGE_STYLES.CENTERED_COLUMN_HALF,
  {
    paddingTop: '0px',
    paddingBottom: '0px',
    [MainForm]: {
      margin: `${SPACING.LARGE} ${SPACING.BASE8X}`,
      gap: SPACING.LARGE,
    },
    [FrictionlessSubmit]: BUTTON_STYLES.FAT,
  },
);

const FrictionlessSubHeading = styled.h2(UI_SUBHEADING_TYPES.LARGE_STANDARD, {
  paddingTop: SPACING.LARGE,
});

class BrandBanner extends Component {
  renderSignupComponent = () => {
    const { t, isAmp, isPhone, navFrom, getAdjustUrl, featureFlags } =
      this.props;
    const adjustBaseUrl = isAmp
      ? Config.APP_STORE_URL_FOR_HOME_AMP
      : Config.APP_STORE_URL_FOR_HOME;
    const adjustUrl = getAdjustUrl(adjustBaseUrl, navFrom);

    if (featureFlags.FRICTIONLESS_SIGNUP_ONLY) {
      return (
        <React.Fragment>
          <FrictionlessSubHeading>
            {t('signup_frictionless_description')}
          </FrictionlessSubHeading>
          <StyledFrictionlesForm
            frictionlessType={FrictionlessFlowType.HOMEPAGE}
          />
          <SignupDisclaimer />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <SubHeader>{t('stories_curated_for_you')}</SubHeader>
        <SubHeaderActionWrapper>
          {isPhone || isAmp ? (
            <Button
              name="brand-banner"
              label={t('download_the_app')}
              styleVariation={StyleVariations.PRIMARY}
              styleModifier={[StyleModifiers.FAT]}
              href={adjustUrl}
            />
          ) : (
            <SignupButton
              name="brand-banner-signup"
              label={t('sign_up')}
              styleVariation={StyleVariations.PRIMARY}
              styleModifier={[StyleModifiers.FAT]}
              displayStyle={USAGE_DISPLAY_STYLES.BRAND_BANNER_CTA}
            />
          )}
        </SubHeaderActionWrapper>
      </React.Fragment>
    );
  };

  render() {
    const { t, isAmp, isPhone } = this.props;

    return (
      <Header className="brand-banner">
        {(isPhone || isAmp) && <Logo height={30} />}
        <Heading>
          <div>{t('get_informed')}</div>
          <div>{t('get_inspired')}</div>
        </Heading>
        {this.renderSignupComponent()}
      </Header>
    );
  }
}

BrandBanner.propTypes = {
  navFrom: PropTypes.string,
  getAdjustUrl: PropTypes.func.isRequired,
  isAmp: PropTypes.bool,
  isPhone: PropTypes.bool.isRequired,
  featureFlags: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

BrandBanner.defaultProps = {
  navFrom: null,
  isAmp: false,
};

export default connector(
  connectAmp,
  connectResponsive,
  connectAppUrl,
  connectFeatureFlags,
)(withT(withNavFrom(withAdjustUrl(BrandBanner))));
