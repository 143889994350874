import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

// Utils
import HocUtil from 'Utils/hoc-util';
import { getSmartMagazineSection } from 'Utils/content/profile-util';
import FlapUtil from 'Utils/content/flap-util';

import { clearRecentlyPersonaizedTopic } from 'Webapp/shared/app/redux/actions/profile-actions';

import AnchoredDialog, {
  ANCHORED_DIALOG_POSITION_X,
  ANCHORED_DIALOG_POSITION_Y,
} from 'ComponentLibrary/utility/anchored-dialog';
import withT from 'ComponentLibrary/hocs/withT';

function withFavorite(Component) {
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);

      this.renderTooltip = this.renderTooltip.bind(this);
    }

    renderTooltip(ref) {
      const {
        recentlyFavoritedPersonalizedTopic,
        clearRecentlyPersonaizedTopic,
        t,
      } = this.props;

      if (!recentlyFavoritedPersonalizedTopic || !ref) {
        return null;
      }
      return (
        <AnchoredDialog
          targetRef={ref}
          styleVariation={AnchoredDialog.styleVariations.TOOLTIP}
          positionX={ANCHORED_DIALOG_POSITION_X.RIGHT}
          positionY={ANCHORED_DIALOG_POSITION_Y.BELOW}
          onClickOutside={clearRecentlyPersonaizedTopic}
          fixed
        >
          <span>
            {t('personalized_topic_favorited', {
              topic: recentlyFavoritedPersonalizedTopic.title,
            })}
          </span>
        </AnchoredDialog>
      );
    }

    render() {
      return <Component {...this.props} renderTooltip={this.renderTooltip} />;
    }
  }

  // Proxy the wrapped component displayName
  WrappedComponent.displayName = HocUtil.displayName(Component);

  WrappedComponent.propTypes = {
    favorites: PropTypes.array,
    lastFavoritedSectionId: PropTypes.string,
    recentlyFavoritedPersonalizedTopic: PropTypes.object,
    clearRecentlyPersonaizedTopic: PropTypes.func,
    t: PropTypes.func,
  };

  const mapStateToProps = ({
    profile: { favorites, smartMagazines, lastFavoritedSectionId },
  }) => {
    const lastFavoritedSection = FlapUtil.getSectionByRemoteId(
      lastFavoritedSectionId,
      favorites,
    );
    const recentlyFavoritedPersonalizedTopic =
      lastFavoritedSection?.isTopic || lastFavoritedSection?.isSmartMagazine
        ? lastFavoritedSection
        : null;

    return {
      favorites,
      isFavorite: (section) => {
        const smartMagazine = getSmartMagazineSection(section, smartMagazines);
        return favorites.some(
          (favorite) =>
            favorite.normalizedRemoteid === section.normalizedRemoteid ||
            favorite.remoteid === section.remoteid ||
            (smartMagazine &&
              (favorite.normalizedRemoteid ===
                smartMagazine.normalizedRemoteid ||
                favorite.remoteid === smartMagazine.remoteid)),
        );
      },
      lastFavoritedSectionId,
      recentlyFavoritedPersonalizedTopic,
    };
  };

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ clearRecentlyPersonaizedTopic }, dispatch);

  return connect(mapStateToProps, mapDispatchToProps)(withT(WrappedComponent));
}
export default withFavorite;
