import React, { Component } from 'react';
import Config from '../../config';
// Utils
import { GA } from 'Utils/analytics';
import FlapUtil from 'Utils/content/flap-util';
import comscorePageView from 'Utils/comscore-page-view';
import { duc } from 'Utils/url';

// Components
import ItemList, {
  ITEM_LIST_STYLE_MODIFIERS,
} from '../views/section/item-list';
import HomeNavigation from './home-navigation';
import Loading from './loading';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import ContentGateModal from '../modals/content-gate';
import ContentGuideUtil from 'Utils/content/content-guide';
import getWindow from 'Utils/get-window';
import VisibilityScrollToBottomTracker from './visibility-scroll-to-bottom-tracker';

import withT from 'ComponentLibrary/hocs/withT';
import withHistory from 'Webapp/shared/app/hocs/withHistory';

import connector from 'Utils/connector';
import connectRouting, {
  ConnectRoutingProps,
} from 'Webapp/shared/app/connectors/connectRouting';
import connectModal, {
  ConnectModalProps,
} from 'Webapp/shared/app/connectors/connectModal';
import { HomeFeedContainerProps } from 'Webapp/shared/app/containers/home-feed';
// Hack to prevent double-tracking of "Featured" section
// when in experiment variation

interface HomeFeedState {
  selectedSectionRemoteId: Flipboard.SectionId;
}

type HomeFeedProps = ConnectRoutingProps &
  ConnectModalProps &
  HomeFeedContainerProps & { t: Flipboard.TFunction; history: object };

class HomeFeed extends Component<HomeFeedProps, HomeFeedState> {
  static defaultProps = {
    topicDescriptions: [],
    contentGuide: null,
    nglFeedConfigs: [],
  };
  constructor(props) {
    super(props);

    this.handleClickSection = this.handleClickSection.bind(this);
    this.getDefaultSectionRemoteId = this.getDefaultSectionRemoteId.bind(this);
    this.trackSectionEnter = this.trackSectionEnter.bind(this);
    this.currentSection = this.currentSection.bind(this);
    this.comscore = this.comscore.bind(this);

    this.state = {
      selectedSectionRemoteId: this.getDefaultSectionRemoteId(),
    };

    this.homeFeedRef = React.createRef();
  }

  async componentDidMount() {
    const { contentGuide } = this.props;
    if (contentGuide === null) {
      await this.props.getContentGuide();
    }
    const section = this.currentSection();
    // if this was SSR, there will be a section
    if (section) {
      // if we already have the section, we are revisiting home and
      // should track
      this.trackSectionEnter();
      // we don't comscore here because every SSR rendered page
      // already does that
    } else {
      // if we don't have one, we should try to get one, it will be
      // tracked later
      this.loadSection();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { contentGuide, sectionLoading } = this.props;
    const { selectedSectionRemoteId } = this.state;
    if (contentGuide === null) {
      return;
    }

    if (prevProps.contentGuide === null && contentGuide) {
      return this.setState({
        selectedSectionRemoteId: this.getDefaultSectionRemoteId(),
      });
    }

    const section = this.currentSection();

    // we are visiting a section we have not yet loaded / currently loading
    if (!section && !sectionLoading[selectedSectionRemoteId]) {
      // load the section
      await this.loadSection();
      // track when loaded
      this.trackSectionEnter();
      this.comscore();
    }

    // the section in question is loaded and we are re-visiting
    if (
      section &&
      prevState.selectedSectionRemoteId !== selectedSectionRemoteId
    ) {
      this.trackSectionEnter();
      this.comscore();
    }
  }

  componentWillUnmount() {
    const { usageTrackSectionExit } = this.props;
    usageTrackSectionExit();
  }

  homeFeedRef: React.RefObject<HTMLDivElement>;

  currentSection() {
    const { entries } = this.props;
    const section = FlapUtil.getSectionByRemoteId(
      this.state.selectedSectionRemoteId,
      entries,
    );
    return section;
  }

  trackSectionEnter() {
    const section = this.currentSection();
    if (section) {
      const { history, usageTrackSectionEnter } = this.props;
      usageTrackSectionEnter(section, history);
    }
  }

  comscore() {
    const section = this.currentSection();
    if (section) {
      comscorePageView();
    }
  }

  async loadSection() {
    const {
      routing: { pathname },
      nglFeedConfigs,
      topicDescriptions,
      getSection,
    } = this.props;
    await getSection(this.state.selectedSectionRemoteId, {
      path: pathname,
      nglFeedConfigs,
      topicDescriptions,
      limit: Config.HOME_FEED_ITEM_LIMIT,
      loadCommentary: true,
      preferMagazineContext: true,
    });
  }

  homeSections() {
    const { contentGuide, lang, locale } = this.props;
    return (
      contentGuide &&
      ContentGuideUtil.getHomeSections(
        contentGuide,
        Config.DEFAULT_SECTIONS,
        Config.HOME_SECTION_ORDER,
        { lang, locale },
      )
    );
  }

  getDefaultSectionRemoteId() {
    const homeSections = this.homeSections();
    return (
      homeSections &&
      homeSections[0] &&
      homeSections[0].remoteid &&
      duc(homeSections[0].remoteid)
    );
  }

  handleClickSection(section) {
    const decodedRemoteId = decodeURIComponent(
      section.remoteid,
    ) as Flipboard.SectionId;

    // track the click in GA
    const eventData = {
      targetTitle: section.title,
      targetRemoteid: decodedRemoteId,
    };
    GA.trackClickButton('home-nav', eventData);

    this.setState({
      selectedSectionRemoteId: decodedRemoteId,
    });
    const homeFeedRef = this.homeFeedRef.current;
    if (!homeFeedRef) {
      return;
    }
    // When switching between feeds, scroll to top of feed (not top of window)
    homeFeedRef.scrollIntoView({ block: 'start', inline: 'start' });
    // Add top paddng on the scroll position
    getWindow().scrollBy(0, -50);
  }

  render() {
    const { isFromCrawler, t, usageTrackReachedLoggedOutHomeEnd } = this.props;
    const { selectedSectionRemoteId } = this.state;
    const section = this.currentSection();
    const items = section && section.items;
    return (
      selectedSectionRemoteId && (
        <div ref={this.homeFeedRef} className="home-feed">
          <HomeNavigation
            selectedSectionRemoteId={selectedSectionRemoteId}
            onClickItem={this.handleClickSection}
            homeSections={this.homeSections()}
          />
          {!section
            ? !isFromCrawler && <Loading />
            : [
                <ItemList
                  section={section}
                  items={items}
                  preventExcerpt
                  key="1"
                  styleModifier={ITEM_LIST_STYLE_MODIFIERS.GRID}
                />,
                <div className="home-feed__read-more" key="2">
                  <React.Fragment>
                    <VisibilityScrollToBottomTracker
                      onScrolledToBottom={usageTrackReachedLoggedOutHomeEnd}
                    />
                    <Button
                      name="home-feed-read-more"
                      label={t('read_more')}
                      onClick={() => this.props.showModal(ContentGateModal)}
                      styleVariation={StyleVariations.PRIMARY}
                    />
                  </React.Fragment>
                </div>,
              ]}
        </div>
      )
    );
  }
}

export default connector(
  connectRouting,
  connectModal,
)(withHistory(withT(HomeFeed)));
