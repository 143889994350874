import connector, { Connector } from 'Webapp/utils/connector';
import { getSection } from '../redux/actions/section-actions';
import { getContentGuide } from '../redux/actions/content-actions';
import HomeFeed from '../components/home-feed';
import {
  usageTrackSectionEnter,
  usageTrackSectionExit,
  usageTrackReachedLoggedOutHomeEnd,
} from 'Webapp/shared/app/redux/actions/usage-actions';

import {
  getSectionEntries,
  getSectionLoading,
  getSectionTopicDescriptions,
} from 'Webapp/shared/app/redux/selectors/section';
import { contentGuide } from 'Webapp/shared/app/redux/selectors/content';
import { currentUserUid } from 'Webapp/shared/app/redux/selectors/auth';

import {
  getNglFeedConfigs,
  getAppLang,
  getLocale,
  isFromCrawler,
} from 'Webapp/shared/app/redux/selectors/app';

const selectors = {
  entries: getSectionEntries,
  sectionLoading: getSectionLoading,
  topicDescriptions: getSectionTopicDescriptions,
  contentGuide,
  userid: currentUserUid,
  nglFeedConfigs: getNglFeedConfigs,
  lang: getAppLang,
  locale: getLocale,
  isFromCrawler,
};

const actions = {
  getSection,
  getContentGuide,
  usageTrackSectionEnter,
  usageTrackSectionExit,
  usageTrackReachedLoggedOutHomeEnd,
};

export type HomeFeedContainerProps = Flipboard.ConnectorProps<
  typeof selectors,
  typeof actions
>;

const homeFeedConnector: Connector = {
  selectors,
  actions,
};

const HomeFeedContainer = connector(homeFeedConnector)(HomeFeed);

export default HomeFeedContainer;
