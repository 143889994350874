import React from 'react';
import {
  homePageStructuredData,
  FlipboardOrganization,
} from 'Utils/content/structured-data';
import StructuredData from './';

const HomeStructuredData = () => [
  <StructuredData
    key="structured-data-home"
    getData={homePageStructuredData}
  />,
  <StructuredData
    key="structured-data-organization"
    getData={() => FlipboardOrganization}
  />,
];

export default HomeStructuredData;
