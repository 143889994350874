import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { WindowScroll } from 'Utils/window-scroll';
import { getSectionPath } from 'Utils/content/flipboard-urls';
import getWindow from 'Utils/get-window';
import ModifierClickAnchor from 'Webapp/shared/app/components/modifier-click-anchor';

class HomeNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = { isLocked: false, unlockPosition: null };
    this.navRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
    this.windowScroll = new WindowScroll(this.handleScroll);
  }
  componentDidMount() {
    this.windowScroll.subscribe(this.handleScroll);
  }

  componentWillUnmount() {
    this.windowScroll.unsubscribe(this.handleScroll);
  }

  handleScroll() {
    const navRef = this.navRef.current;
    if (!navRef) {
      return;
    }
    const { isLocked, unlockPosition } = this.state;
    const lockPosition = getWindow().scrollY + 60;

    if (!isLocked && navRef.offsetTop < lockPosition) {
      const elPosition = navRef.getBoundingClientRect();
      this.setState({
        isLocked: true,
        unlockPosition: navRef.offsetTop + 55 - elPosition.height,
      });
    } else if (isLocked && lockPosition < unlockPosition) {
      this.setState({ isLocked: false, unlockPosition: null });
    }
  }

  render() {
    const { homeSections, selectedSectionRemoteId } = this.props;
    const classes = classNames({
      'home-navigation': true,
      'home-navigation--locked': this.state.isLocked,
    });
    return (
      <div className="home-navigation__wrapper">
        <nav className={classes} ref={this.navRef}>
          <ul>
            {homeSections.map((section) => {
              const decodedSectionRemoteId = decodeURIComponent(
                section.remoteid,
              );
              const itemClasses = classNames({
                'home-navigation__item': true,
                'home-navigation__item--unselected':
                  selectedSectionRemoteId &&
                  selectedSectionRemoteId !== decodedSectionRemoteId,
              });
              return (
                <li
                  className={itemClasses}
                  data-test-id="home-navigation-item"
                  key={section.title}
                >
                  <ModifierClickAnchor
                    onClick={() => this.props.onClickItem(section)}
                    href={section.path || getSectionPath(section)}
                  >
                    {section.title}
                  </ModifierClickAnchor>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    );
  }
}

HomeNavigation.propTypes = {
  selectedSectionRemoteId: PropTypes.string,
  onClickItem: PropTypes.func.isRequired,
  homeSections: PropTypes.array.isRequired,
};

export default HomeNavigation;
