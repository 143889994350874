import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { LAYOUT_SIZES } from 'Style/layout';

// Util
import { GA } from 'Utils/analytics';
import { USAGE_NAV_FROMS } from 'Utils/analytics/usage';
import { homeFeedAppUrl } from 'Utils/content/flipboard-urls';

// Components
import Input, { InputIcons } from 'Webapp/shared/app/components/base/input';
import { MODAL_THEMES, MODAL_MODIFIERS } from 'ComponentLibrary/modals/modal';
import SearchModal from '../modals/search';
import PageClasses from '../components/page-classes';
import BrandBanner from '../components/brand-banner';
import SectionTiles from 'Webapp/shared/app/components/section-tiles';
import ForYouFeedContainer from '../containers/for-you-feed';
import HomeFeedContainer from '../containers/home-feed';
import HomeStructuredData from '../structured-data/home';
import { NavFromContext } from 'ComponentLibrary/context';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAmp from '../connectors/connectAmp';
import connectModal from '../connectors/connectModal';
import connectResponsive from '../connectors/connectResponsive';
import connectAuthentication from '../connectors/connectAuthentication';
import connectAppUrl from '../connectors/connectAppUrl';
import connectAdjustParams from '../connectors/connectAdjustParams';
import connectFlipMode from 'Webapp/shared/app/connectors/connectFlipMode';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';

import { SPACING } from 'Style/spacing';

const HomeSectionTiles = styled(SectionTiles)({
  margin: `${SPACING.BASE6X} 0`,
  padding: 0,
});
const StyledPageClasses = styled(PageClasses)((props) => ({
  paddingTop: props.isFlipModeEnabled
    ? LAYOUT_SIZES.MAIN_NAV_PLUS_FLIP_MODE_HEIGHT
    : null,
}));

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startedAuthenticated: this.props.isAuthenticated,
    };
  }

  componentDidMount() {
    // Set app URL for home
    this.props.setAppUrl(homeFeedAppUrl);

    // Reset Adjust URL in nav bar
    this.props.setNavAppStoreUrl();
    this.props.setAdjustNavFrom(USAGE_NAV_FROMS.HOME);

    GA.trackPageview(this.props.setTitle(), this.props.routing.pathname);
  }

  componentWillUnmount() {
    this.props.setAppUrl(undefined);
    this.props.setAdjustNavFrom(undefined);
  }

  renderLoggedOutContent = () => {
    const { isAmp, isPhone, showModal, mobileHomeTileSections, t } = this.props;
    const handleSearchClick = () => {
      showModal(SearchModal, {
        autoFocus: true,
        variant: MODAL_THEMES.FULLSCREEN,
        modifier: MODAL_MODIFIERS.HAS_FIXED_HEADER,
      });
    };
    return (
      <React.Fragment>
        {!isAmp && isPhone && (
          <div className="home__search" onClick={handleSearchClick}>
            <Input
              // This input is essentially a button to trigger the search modal, so disallow tabbing
              tabIndex={-1}
              name="searchHome"
              placeholder={t('search_flipboard')}
              icon={InputIcons.SEARCH}
            />
          </div>
        )}
        <BrandBanner />
        {!isAmp && !isPhone ? (
          <HomeFeedContainer />
        ) : (
          <HomeSectionTiles
            sections={mobileHomeTileSections}
            collapsable={false}
            title={false}
            highDisplayCount
          />
        )}
      </React.Fragment>
    );
  };

  renderContent = () => {
    const { startedAuthenticated } = this.state;
    const { isAuthenticated, signupCompleted, modalLoginComplete } = this.props;

    if (
      startedAuthenticated ||
      (isAuthenticated && (signupCompleted || modalLoginComplete))
    ) {
      return <ForYouFeedContainer />;
    }

    return this.renderLoggedOutContent();
  };

  render() {
    const { isAuthenticated, isFlipModeEnabled } = this.props;

    const classes = classNames({
      home: true,
      'layout--main-unauthenticated': !isAuthenticated,
    });

    return (
      <NavFromContext.Provider value={USAGE_NAV_FROMS.HOME}>
        <StyledPageClasses
          className={classes}
          enableFullWidth
          isFlipModeEnabled={isFlipModeEnabled}
        >
          <div className="centered-column">
            <HomeStructuredData />
            {this.renderContent()}
          </div>
        </StyledPageClasses>
      </NavFromContext.Provider>
    );
  }
}

Home.propTypes = {
  routing: PropTypes.object.isRequired,
  isAmp: PropTypes.bool.isRequired,
  isPhone: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool,
  signupCompleted: PropTypes.bool,
  t: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  setAppUrl: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  setNavAppStoreUrl: PropTypes.func.isRequired,
  setAdjustNavFrom: PropTypes.func.isRequired,
  mobileHomeTileSections: PropTypes.array.isRequired,
  isFlipModeEnabled: PropTypes.bool.isRequired,
  modalLoginComplete: PropTypes.bool.isRequired,
};

Home.defaultProps = {
  isAmp: false,
};

export default connector(
  connectRouting,
  connectAmp,
  connectModal,
  connectResponsive,
  connectAuthentication,
  connectAppUrl,
  connectAdjustParams,
  connectFlipMode,
)(withT(Home));
