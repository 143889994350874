import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { COLORS } from 'Style/colors';

// Utils
import { USAGE_DISPLAY_STYLES } from 'Utils/analytics/usage';

// Components
import ModalControls from 'Webapp/shared/app/components/modal-controls';
import { MODAL_THEMES } from 'ComponentLibrary/modals/modal';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import SignupButton from 'Webapp/shared/app/containers/signup-button';
import ModalWrapper from './modal-wrapper';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

const ContentGateTopic = styled.span({ color: COLORS.red });

const Controls = styled(ModalControls)({ marginTop: SPACING.BASE8X });
function ContentGateModal({ allowDismiss, topicTag, dismissModal, t }) {
  return (
    <ModalWrapper name="content-gate" modalTheme={MODAL_THEMES.WINDOW}>
      <section className="content-gate modal__content">
        {topicTag ? (
          <h1 className="content-gate__title ui-text--title">
            {t('want_more_stories_on')}{' '}
            <ContentGateTopic>#{topicTag}</ContentGateTopic>?
          </h1>
        ) : (
          <h1 className="content-gate__title ui-text--title">
            {t('want_more_stories')}
          </h1>
        )}
        <p className="ui-text--body">{t('sign_up_to_see_more')}</p>
      </section>
      <Controls>
        {allowDismiss && (
          <Button
            name="content-gate-dismiss"
            label={t('no_thanks')}
            styleVariation={StyleVariations.SECONDARY}
            onClick={dismissModal}
          />
        )}
        <SignupButton
          name="content-gate-signup"
          label={t('continue')}
          styleVariation={StyleVariations.PRIMARY}
          displayStyle={USAGE_DISPLAY_STYLES.CONTENT_GATE_CTA}
        />
      </Controls>
    </ModalWrapper>
  );
}

ContentGateModal.propTypes = {
  allowDismiss: PropTypes.bool,
  topicTag: PropTypes.string,
  t: PropTypes.func.isRequired,
  dismissModal: PropTypes.func.isRequired,
};

ContentGateModal.defaultProps = {
  allowDismiss: false,
  topicTag: null,
};

export default connector(connectModal)(withT(ContentGateModal));
