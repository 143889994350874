import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Webapp/shared/app/components/button';
import PersonalizeForYouModal from 'Webapp/shared/app/containers/modals/personalize-for-you';
import PersonalizeIcon from 'ComponentLibrary/icons/personalize';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

class PersonalizeForYouButton extends React.Component {
  constructor(props) {
    super(props);
    this.show = this.show.bind(this);
  }

  show() {
    this.props.showModal(PersonalizeForYouModal);
  }

  render() {
    return (
      <Button name="personalize-for-you" onClick={this.show}>
        <PersonalizeIcon size={18} isPersonalized />
      </Button>
    );
  }
}
PersonalizeForYouButton.propTypes = {
  showModal: PropTypes.func.isRequired,
};
PersonalizeForYouButton.defaultProps = { isPersonalized: false };

export default connector(connectModal)(PersonalizeForYouButton);
