import React from 'react';
import PropTypes from 'prop-types';

/**
 * Anchor tag component that allows a click with a modifier key pressed
 * to take presedence over another click handler.
 */
const ModifierClickAnchor = ({
  allowedModifierKeys,
  children,
  href,
  onClick,
  ...restProps
}) => {
  const onClickWrapper = (e) => {
    for (const key of allowedModifierKeys) {
      if (e[key]) {
        return;
      }
    }

    e.preventDefault();
    onClick(e);
  };

  return (
    <a onClick={onClickWrapper} href={href} {...restProps}>
      {children}
    </a>
  );
};

ModifierClickAnchor.propTypes = {
  allowedModifierKeys: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  href: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ModifierClickAnchor.defaultProps = {
  allowedModifierKeys: ['ctrlKey', 'metaKey', 'shiftKey'],
};

export default ModifierClickAnchor;
