import connector from 'Utils/connector';
import { getEditorialBoards } from 'Webapp/shared/app/redux/actions/content-actions';
import {
  fetchFollowing,
  setFollowedTopics,
} from 'Webapp/shared/app/redux/actions/profile-actions';
import { usageTrackPersonalizeForYou } from 'Webapp/shared/app/redux/actions/usage-actions';

import PersonalizeForYouModal from 'Webapp/shared/app/modals/personalize-for-you';

import SectionUtil from 'Utils/content/section-util';

export default connector({
  selectors: {
    editorialBoards: ({ content: { editorialBoards } }) => editorialBoards,
    followedTopics: ({ profile: { following, followingIds } }) => {
      if (following.length === 0 && followingIds.length > 0) {
        return null;
      }
      return following.filter(SectionUtil.isTopic);
    },
  },
  actions: {
    fetchFollowing,
    getEditorialBoards,
    setFollowedTopics,
    usageTrackPersonalizeForYou,
  },
})(PersonalizeForYouModal);
