import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Config from 'Webapp/shared/config';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import {
  BODY_TYPES,
  UI_HEADING_TYPES,
  UI_SUBHEADING_TYPES,
} from 'Style/typography';
import { PAGE_STYLES, LAYOUT_SIZES } from 'Style/layout';

// Utils
import SectionUtil from 'Utils/content/section-util';
import { USAGE_EVENT_NAMES, USAGE_NAV_FROMS } from 'Utils/analytics/usage';

// Components
import { NavFromContext } from 'ComponentLibrary/context';
import Loading from 'Webapp/shared/app/components/loading';
import LoadMore from 'Webapp/shared/app/components/load-more';
import PersonalizeForYouButton from 'Webapp/shared/app/components/personalize-for-you-button';
import ItemList, {
  ITEM_LIST_STYLE_MODIFIERS,
} from '../views/section/item-list';
import Button, {
  StyleVariations,
  StyleModifiers,
} from 'Webapp/shared/app/components/button';
import DescriptionSectionsList from 'Webapp/shared/app/views/section/topic/description-sections-list';

import withHistory from 'Webapp/shared/app/hocs/withHistory';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';

const MAX_DESCRIPTION_SECTIONS = 12;

const ForYouWrapper = styled.div();
const ForYouHeader = styled.header(PAGE_STYLES.CENTERED_COLUMN, {
  marginTop: SPACING.BASE8X,
  marginBottom: SPACING.XLARGE,
});

const ForYouTitleRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: SPACING.BASE,
});
const ForYouTitle = styled.h1(UI_HEADING_TYPES.XLARGE, {
  cursor: 'pointer',
});
const ForYouSubTitle = styled.h1(UI_SUBHEADING_TYPES.LARGE_STANDARD, {
  marginBottom: SPACING.LARGE,
});

const ForYouDescription = styled(DescriptionSectionsList)(
  BODY_TYPES.LARGE_STANDARD,
  {
    maxWidth: LAYOUT_SIZES.MAIN_COLUMN_HALF_WIDTH,
  },
);

const FeedLoading = styled(Loading)({
  height: '50vh',
});

class ForYouFeed extends Component {
  constructor(props) {
    super(props);
    this.loadSection = this.loadSection.bind(this);
    this.loadNextPage = this.loadNextPage.bind(this);
  }

  componentDidMount() {
    // Use route params
    const { section, history, usageTrackSectionEnter, setSectionAccessed } =
      this.props;

    if (section && !section.placeholder) {
      usageTrackSectionEnter(section, history);
      setSectionAccessed(section);
      if (section.stale) {
        this.loadSection({ definitelyRefresh: true });
      }
    } else {
      this.loadSection({ definitelyRefresh: true });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      section,
      history,
      usageTrackSectionEnter,
      followingStale,
      fetchFollowing,
      followingLoading,
      userInfo,
      isPhone,
    } = this.props;
    if (
      prevProps.section &&
      prevProps.section.placeholder &&
      section &&
      !section.placeholder
    ) {
      usageTrackSectionEnter(section, history);
    }

    if (
      prevProps.userInfo === null &&
      userInfo &&
      !userInfo.followingSampleLoaded &&
      !isPhone
    ) {
      // when userInfo comes into existence, we will have finished
      // loading the profile and should get following (this will be
      // what makes followed available to DescriptionSectionsList)
      return fetchFollowing({ sample: true });
    }

    if (followingStale && !followingLoading) {
      fetchFollowing();
      this.loadSection({ definitelyRefresh: true });
    }

    if (prevProps.section?.stale === false && section.stale) {
      this.loadSection({ definitelyRefresh: true });
    }
  }

  componentWillUnmount() {
    const { usageTrackSectionExit } = this.props;
    usageTrackSectionExit();
  }

  loadSection(options = {}) {
    const {
      topicDescriptions,
      nglFeedConfigs,
      previousRawItems,
      routing: { pathname },
      getSection,
    } = this.props;
    return getSection(Config.FOR_YOU_FEED_REMOTE_ID, {
      path: pathname,
      forNavigation: true,
      nglFeedConfigs,
      previousRawItems,
      topicDescriptions,
      preferMagazineContext: true,
      ...options,
    });
  }

  loadNextPage() {
    const { nextPageKey } = this.props;
    return this.loadSection({ pageKey: nextPageKey });
  }

  render() {
    const {
      isLoading,
      section,
      followedTopics,
      items,
      neverLoadMore,
      isPhone,
      refreshForYou,
      t,
    } = this.props;

    const filteredItems = items.filter((item) => !item.isGroup);
    const hasItems = SectionUtil.hasItems(section, items);
    const seeMoreLink = (
      <Button
        name="see-all-following"
        href="/following"
        styleVariation={StyleVariations.PRIMARY_TEXT}
        styleModifier={[StyleModifiers.NO_LEFT_PADDING]}
        navFromEventName={USAGE_EVENT_NAMES.TOC_ENTER}
      >
        {t('see_all')}
      </Button>
    );

    return (
      <ForYouWrapper>
        <ForYouHeader>
          <ForYouTitleRow>
            <ForYouTitle onClick={refreshForYou}>
              {t('for_you_title')}
            </ForYouTitle>
            <PersonalizeForYouButton />
          </ForYouTitleRow>
          <ForYouSubTitle>{t('for_you_description')}</ForYouSubTitle>
          {!isPhone && (
            <React.Fragment>
              {followedTopics && (
                <NavFromContext.Provider value={USAGE_NAV_FROMS.FOR_YOU_HEADER}>
                  <ForYouDescription
                    sections={followedTopics}
                    seeMoreLink={seeMoreLink}
                    maxSections={MAX_DESCRIPTION_SECTIONS}
                  />
                </NavFromContext.Provider>
              )}
            </React.Fragment>
          )}
        </ForYouHeader>
        <div>
          {isLoading ? (
            <FeedLoading />
          ) : (
            <React.Fragment>
              {hasItems && (
                <ItemList
                  section={section}
                  items={filteredItems}
                  styleModifier={ITEM_LIST_STYLE_MODIFIERS.GRID}
                />
              )}
              {hasItems && !neverLoadMore && (
                <LoadMore
                  immediateInfinite
                  loadNext={this.loadNextPage}
                  isLoading={isLoading}
                />
              )}
            </React.Fragment>
          )}
        </div>
      </ForYouWrapper>
    );
  }
}

ForYouFeed.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getSection: PropTypes.func.isRequired,
  setSectionAccessed: PropTypes.func.isRequired,
  routing: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  section: PropTypes.object,
  items: PropTypes.array,
  followingStale: PropTypes.bool.isRequired,
  followingLoading: PropTypes.bool.isRequired,
  followedTopics: PropTypes.array,
  topicDescriptions: PropTypes.array,
  nextPageKey: PropTypes.string,
  previousRawItems: PropTypes.array,
  nglFeedConfigs: PropTypes.array,
  neverLoadMore: PropTypes.bool.isRequired,
  isPhone: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  usageTrackSectionEnter: PropTypes.func.isRequired,
  usageTrackSectionExit: PropTypes.func.isRequired,
  fetchFollowing: PropTypes.func.isRequired,
  refreshForYou: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

ForYouFeed.defaultProps = {
  items: [],
  section: null,
  nextPageKey: null,
  previousRawItems: null,
  isLoading: true,
};

export default connector(
  connectRouting,
  connectResponsive,
)(withHistory(withT(ForYouFeed)));
