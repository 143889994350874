import React from 'react';
import PropTypes from 'prop-types';
import replaceTags from 'Utils/i18n/replace-tags';
import {
  USAGE_NAV_FROMS,
  USAGE_FOLLOWING_METHODS,
} from 'Utils/analytics/usage';
import SectionUtil from 'Utils/content/section-util';

import { MODAL_THEMES, MODAL_MODIFIERS } from 'ComponentLibrary/modals/modal';
import ModalWrapper from 'Webapp/shared/app/modals/modal-wrapper';
import ModalControls from 'Webapp/shared/app/components/modal-controls';
import TopicPicker from 'Webapp/shared/app/components/topic-picker';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import ConfirmDismiss from 'Webapp/shared/app/modals/confirm-dismiss';
import ConfirmDialog from 'Webapp/shared/app/modals/confirm-dialog';

import withT from 'ComponentLibrary/hocs/withT';
import withFavorite from 'Webapp/shared/app/hocs/withFavorite';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

class PersonalizeForYouModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isChanged: false,
      favsRemoved: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  componentDidMount() {
    const {
      editorialBoards,
      getEditorialBoards,
      followedTopics,
      fetchFollowing,
    } = this.props;
    if (!followedTopics) {
      this.setState({ isLoading: true }, () => fetchFollowing());
    }
    if (!editorialBoards) {
      this.setState({ isLoading: true }, () => getEditorialBoards());
    }
  }

  componentDidUpdate() {
    const { editorialBoards, followedTopics } = this.props;
    if (this.state.isLoading && editorialBoards && followedTopics) {
      this.setState({ isLoading: false });
    }
  }

  async executeSubmit(updatedSelection) {
    const { dismissModal, usageTrackPersonalizeForYou, setFollowedTopics } =
      this.props;
    usageTrackPersonalizeForYou();
    await setFollowedTopics(
      updatedSelection,
      USAGE_NAV_FROMS.PERSONALIZE_SHEET,
      USAGE_FOLLOWING_METHODS.PERSONALIZE_FOR_YOU,
    );
    dismissModal();
  }
  async handleSubmit(updatedSelection) {
    const { showModal, isFavorite, followedTopics, t } = this.props;
    const removedFavorites = followedTopics.reduce((acc, s) => {
      if (
        !updatedSelection.find((x) => SectionUtil.mightBeSameSection(s, x)) &&
        isFavorite(s)
      ) {
        acc.push(s);
      }
      return acc;
    }, []);
    if (removedFavorites.length > 0) {
      showModal(ConfirmDialog, {
        name: 'confirm-personalize-for-you',
        message: t('confirm_unfollow_favorite', {
          count: removedFavorites.length,
          topicTitle: removedFavorites.map((fav) => `#${fav.title}`).join(', '),
        }),
        title: t('are_you_sure'),
        cancelLabel: t('back'),
        confirmLabel: t('save'),
        onConfirm: async () => await this.executeSubmit(updatedSelection),
      });
    } else {
      await this.executeSubmit(updatedSelection);
    }
  }

  handleCancel(dirty) {
    return ({ isSearching, clearSearch } = {}) => {
      const { dismissModal, showModal, t } = this.props;
      if (dirty) {
        return showModal(ConfirmDismiss, {
          name: 'confirm-personalize-for-you',
          message: t('confirm_discard_changes'),
          title: t('discard_changes'),
          cancelLabel: t('back'),
          confirmLabel: t('discard'),
        });
      }

      if (isSearching) {
        return clearSearch();
      }
      dismissModal();
    };
  }

  render() {
    const { isPhone, editorialBoards, followedTopics, t } = this.props;
    const modalTheme = isPhone ? MODAL_THEMES.FULLSCREEN : MODAL_THEMES.WINDOW;

    const pickerTitle = (
      <React.Fragment>
        {replaceTags(
          t('personalize_topic', {
            topicTitle: t('for_you_title'),
          }),
          {
            topicTag: () => (
              <span className="red-text">{t('for_you_title')}</span>
            ),
          },
        )}
      </React.Fragment>
    );

    return (
      <TopicPicker
        title={pickerTitle}
        description={t('more_stories_from_what_you_follow')}
        noResultsText={t('no_results')}
        className="modal__content"
        onSubmit={this.handleSubmit}
        selectedTitle={t('topics_following')}
        followableGroups={[
          { title: t('more_topics_to_follow'), subsections: editorialBoards },
        ]}
        followed={followedTopics || []}
        isLoadingFollowableGroups={this.state.isLoading}
        collapsible
      >
        {({
          content,
          submit,
          isSubmittable,
          isSearching,
          clearSearch,
          isDirty,
        }) => (
          <ModalWrapper
            name="personalize-for-you-modal"
            modalTheme={modalTheme}
            modifier={MODAL_MODIFIERS.HAS_NO_HEADER}
            interceptDismiss={this.handleCancel(isDirty)}
          >
            {content}
            <ModalControls
              className="personalize-for-you-controls"
              fixedControls
            >
              <Button
                name="topic-customization-cancel"
                styleVariation={StyleVariations.SECONDARY}
                onClick={() => {
                  this.handleCancel(isDirty)({ isSearching, clearSearch });
                }}
              >
                {isSearching ? t('back') : t('cancel')}
              </Button>
              <Button
                name="topic-customization-done"
                className="topic-customization__submit-button"
                styleVariation={StyleVariations.PRIMARY}
                onClick={submit}
                disabled={!isSubmittable}
              >
                {isSearching ? t('next') : t('save')}
              </Button>
            </ModalControls>
          </ModalWrapper>
        )}
      </TopicPicker>
    );
  }
}

PersonalizeForYouModal.propTypes = {
  editorialBoards: PropTypes.array,
  followedTopics: PropTypes.array,
  getEditorialBoards: PropTypes.func.isRequired,
  fetchFollowing: PropTypes.func.isRequired,
  isPhone: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  dismissModal: PropTypes.func.isRequired,
  setFollowedTopics: PropTypes.func.isRequired,
  usageTrackPersonalizeForYou: PropTypes.func.isRequired,
  isFavorite: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default connector(
  connectModal,
  connectResponsive,
)(withT(withFavorite(PersonalizeForYouModal)));
