import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setAdjustNavFrom,
  setNavAppStoreUrl,
  setTitle,
  setAppUrl,
} from '../redux/actions/app';
import Home from '../views/home';

const mapStateToProps = ({ app: { mobileHomeTileSections } }) => ({
  mobileHomeTileSections,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { setAdjustNavFrom, setNavAppStoreUrl, setTitle, setAppUrl },
    dispatch,
  );

const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(Home);

export default HomeContainer;
