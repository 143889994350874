import React from 'react';
import PropTypes from 'prop-types';
import replaceTags from 'Utils/i18n/replace-tags';

import withT from 'ComponentLibrary/hocs/withT';
import SectionTag, {
  STYLE_VARIATIONS as TOPIC_TAG_STYLE_VARIATIONS,
} from 'Webapp/shared/app/components/section-tag';

const MAX_RELATED_SECTIONS = 9;
function DescriptionSectionsList({
  className,
  sections,
  personalizedName,
  seeMoreLink,
  maxSections,
  t,
}) {
  if (!sections || sections.length <= 0) {
    return null;
  }

  const relatedSectionsList = sections.slice(0, maxSections);
  const relatedSectionsString = relatedSectionsList
    .map((topic) => topic.title)
    .join(', ');
  const relatedSectionComponents = relatedSectionsList
    .map((section) => (
      <SectionTag
        key={section.remoteid}
        section={section}
        styleVariation={TOPIC_TAG_STYLE_VARIATIONS.INHERIT_UNDERLINE}
      />
    ))
    .reduce((prev, curr) => [prev, ', ', curr]);

  const text = personalizedName
    ? t('personalized_with_stories_in', {
        profileName: personalizedName,
        topicList: relatedSectionsString,
      })
    : t('with_stories_in', {
        topicList: relatedSectionsString,
      });

  return (
    <div className={`${className} topic-header__related`}>
      {replaceTags(text, {
        decoratedTopicList: () => relatedSectionComponents,
      })}
      {seeMoreLink && (
        <React.Fragment>
          <br />
          {seeMoreLink}
        </React.Fragment>
      )}
    </div>
  );
}

DescriptionSectionsList.propTypes = {
  className: PropTypes.string,
  sections: PropTypes.array,
  personalizedName: PropTypes.string,
  maxSections: PropTypes.number,
  seeMoreLink: PropTypes.object,
  t: PropTypes.func.isRequired,
};

DescriptionSectionsList.defaultProps = {
  maxSections: MAX_RELATED_SECTIONS,
};
export default withT(DescriptionSectionsList);
